import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import crossblack_icon from "../../assets/images/png/blackcrose_icon.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { uploadProduct } from "../../redux/slice/productSlice";
import ExcelSheet from "../../assets/sample/instruction-sample.zip";
const ImoprtModal = ({ handleImportChange, imports }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const modalStyles = {
    maxWidth: "400px", // Set the desired width
    margin: "auto", // Center the modal horizontally
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // List of allowed Excel file extensions
      const allowedExtensions = [
        "xlsx",
        "xlsm",
        "xlsb",
        "xltx",
        "xltm",
        "xls",
        "xlt",
        "xml",
        "xlam",
        "xla",
        "xlw",
        "xlr",
      ];
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      // Check if the file extension is in the allowed list
      if (allowedExtensions.includes(fileExtension)) {
        setSelectedFile(file);
      } else {
        toast.error("Please select a valid Excel sheet file.");
      }
    }
  };

  const submitHandle = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("excel_file", selectedFile);
      dispatch(uploadProduct(formData));
    } else {
      toast.error("Please select a file");
    }
  };
  return (
    <div>
      <Modal
        className="imprt_modal position-absolute top-50 start-50 translate-middle  position-relative"
        centered
        show={imports}
      >
        <div className="mt-3">
          <a
            className="mt-2 ms-2 fs_16"
            href={ExcelSheet}
            download="sample.zip"
          >
            {/* <button className="btn btn-info"> */}
            Download Sample Sheet
            {/* </button> */}
          </a>
        </div>
        <div style={modalStyles} className="position-absolute top-0 end-0">
          <img
            onClick={handleImportChange}
            width={20}
            className="me-2 mt-2 cursor-pointer"
            src={crossblack_icon}
            alt="Close"
          />
        </div>
        <div>
          <Modal.Body className="px-2 pb-0">
            <label
              className="mb-2 fw-semibold"
              style={{ fontSize: "14px" }}
              htmlFor=""
            >
              Import Excel Sheet
            </label>
            <input
              type="file"
              className="custom_admin_input w-100 px-3 py-2"
              onChange={handleFileChange}
            />
            {/* <h5 className="my-4">Instructions</h5>
            <div className=" pb-3">
              <p className="ff_heebo fw-normal fs_15 mb-1">
                <strong>1.</strong> Lorem ipsum dolor sit amet.
              </p>
              <p className="ff_heebo fw-normal fs_15 mb-1">
                <strong>2.</strong> Lorem ipsum dolor sit amet.
              </p>
              <p className="ff_heebo fw-normal fs_15 mb-1">
                <strong>3.</strong> Lorem ipsum dolor sit amet.
              </p>
            </div> */}
          </Modal.Body>
          <Modal.Footer className="">
            <Button
              variant="secondary"
              className="submit_btn"
              onClick={submitHandle}
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default ImoprtModal;
