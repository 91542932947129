import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slice/authSlice'; 
import responseSlice from '../slice/responseSlice';
import productSlice from '../slice/productSlice';
import categorySlice from '../slice/categorySlice';
import unitSlice from '../slice/unitSlice';

const rootReducer = combineReducers({
  auth: authReducer,
   response:responseSlice,
   product:productSlice,
   category:categorySlice,
   unit :unitSlice,
});

export default rootReducer;
