// categoriesSaga.js

import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios'; 
import {  getUnitsSuccess, getUnitsFailure } from '../slice/unitSlice';
import { getToken } from '../../utils/appConfig';
const baseUrl = process.env.REACT_APP_API_URL;

function* getUnits(action) {
  try {

    const response = yield call(() => axios.get(`${baseUrl}/get-unit`,{
        headers:{
          Authorization: `Bearer ${getToken()}`
        }
    }));
    yield put(getUnitsSuccess(response.data.data));
  } catch (error) {
    yield put(getUnitsFailure(error.message));
  }
}

// Watcher saga
export default function* unitSaga() {
  yield takeLatest('unit/getUnits', getUnits);
}
