import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import { toast } from 'react-toastify';
import { Route, Routes, Navigate } from "react-router-dom";
import CourseTab from "./components/tejar-supplier-admin-panel/CourseTab";
import BlogTab from "./components/tejar-supplier-admin-panel/BlogTab";
import AdminMainTemplate from "./Pages/Admin/AdminMainTemplate";
import AdminLoginPage from "./components/tejar-supplier-admin-panel/AdminLoginPage";
import ProductDetails from "./components/tejar-supplier-admin-panel/ProductDetails";
import ForgetPassword from "./components/tejar-supplier-admin-panel/ForgetPassword";
import { useDispatch, useSelector } from "react-redux";
import { clearError, clearSuccess } from "./redux/slice/responseSlice";
import { useEffect } from "react";
import Cookies from 'js-cookie';
function App() {
  const data = Cookies.get('auth');
  let auth;
  if (data) {
    auth = JSON.parse(data)
  }
  const dispatch = useDispatch()
  const { success, error } = useSelector((state) => state.response);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {

    if (success) {
      toast.success(success)
      setTimeout(() => {
        dispatch(clearSuccess())
      }, 600);
    }
    if (error) {
      toast.error(error)
      setTimeout(() => {
        dispatch(clearError())
      }, 600);

    }
  }, [success, error])

  return (
    <>
      <Routes>
        {auth?.token ? (
          <Route path="/" element={<AdminMainTemplate />}>
            <Route path="products" element={<CourseTab />} />
            <Route path="settings" element={<BlogTab />} />
            <Route path="products/:id" element={<ProductDetails />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<AdminLoginPage />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
          </>
        )}
        {/* Redirect to login page if not authenticated */}
        <Route path="*" element={<Navigate to={auth ? "/products" : "/"} />} />
      </Routes>
    </>
  );
}

export default App;
