// authSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  loading: false,
  error: null,
  success:false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state) {
      state.loading = true;
      state.error = null;
      state.success=false
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
      state.success=true
    },
    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    forgetPassword(state) {
      state.loading = true;
      state.error = null;
    },
    forgetPasswordSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    forgetPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    verifyOtp(state) {
      state.loading = true;
      state.error = null;
    },
    verifyOtpSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    verifyOtpFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    newPassword(state) {
      state.loading = true;
      state.error = null;
    },
    newPasswordSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    newPasswordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateUserData(state,action){
      state.user=action.payload;
      state.success=false
    }
  },
});

export const {
  login,
  loginSuccess,
  loginFailure,
  forgetPassword,
  forgetPasswordSuccess,
  forgetPasswordFailure,
  verifyOtp,
  verifyOtpSuccess,
  verifyOtpFailure,
  newPassword,
  newPasswordSuccess,
  newPasswordFailure,
  updateUserData
} = authSlice.actions;

export default authSlice.reducer;
