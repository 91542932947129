import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;

export const sendOtp = async (data) => {
    const url = baseUrl + `/forget-password`;
    try {
        const response = await axios.post(url, data)
        return response.data
    } catch (error) {
        return error.response.data

    }
}
export const verifyOtp = async (data) => {
    const url = baseUrl + `/supplier-otp-verify`;
    try {
        const response = await axios.post(url, data)
        return response.data
    } catch (error) {
        return error.response.data

    }
}
export const resetPassword = async (data) => {
    const url = baseUrl + `/supplier-reset-password`;
    try {
        const response = await axios.post(url, data)
        return response.data
    } catch (error) {
        return error.response.data

    }
}