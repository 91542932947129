import React, { useEffect, useState } from "react";
import crossblack_icon from "../../assets/images/png/blackcrose_icon.png";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addStock } from "../../redux/slice/productSlice";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap/esm";

const AddStock = ({ type, unit, toggleAddStokModal, addStok }) => {
  const { loading, singleProduct, success } = useSelector(
    (state) => state.product
  );
  const dispatch = useDispatch();
  console.log("single product in add stock ", singleProduct);
  const { id } = useParams();
  const [stock, setStock] = useState("");
  const addStockHandle = () => {
    if (!stock) {
      toast.error("Please Enter the Quantity");
      return;
    }
    const data = {
      product_id: singleProduct?.product_id,
      stock: stock,
      type: 2,
    };
    dispatch(addStock({ data: data }));
  };
  useEffect(() => {
    if (success == "stock") {
      toggleAddStokModal();
    }
  }, [success]);
  return (
    <div>
      {" "}
      <Modal show={addStok} centered className="">
        <div className="rounded-3 px-4 card position-relative">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleAddStokModal}
              width={20}
              className="me-2 mt-2 cursor-pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>

          <Modal.Body>
            <h4>Add Stock</h4>
            {/* <form className="mt-4"> */}
            <div className="d-flex w-100 gap-3 align-items-center col-3 mt-4">
              <div className="d-flex flex-column mt-3">
                <lable>Add Stock</lable>
                <input
                  onChange={(e) => setStock(e.target.value)}
                  className="custom_admin_input mt-2 px-3 py-2 w-100"
                  type="number"
                  name="title"
                />
              </div>

              <div className="d-flex flex-column mt-3">
                <lable>Unit</lable>
                <input
                  disabled
                  value={unit}
                  className="custom_admin_input mt-2 px-3 py-2 "
                  style={{ maxWidth: "100px" }}
                  type="text"
                  name="title"
                />
              </div>
            </div>

            <span>
              <button
                onClick={addStockHandle}
                className="bg-primary mt-4 submit_btn"
                type="submit"
              >
                {loading ? <Spinner /> : "Submit"}
              </button>
            </span>
            {/* </form> */}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default AddStock;
