import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import login_img from "../../assets/images/png/login_img.png";
import { ToastContainer, toast } from "react-toastify";
import { resetPassword, sendOtp, verifyOtp } from "../../services/ResetPassword";
import { Spinner } from "react-bootstrap";

function ForgetPassword() {
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [otpSend, setOtpSend] = useState(false);
const navigate=useNavigate()
  const sendOtpHandle = () => {
    if (email) {
      setLoading(true)
      sendOtp({ email: email }).then((res) => {
        setLoading(false)
        if (res.status == true) {
          toast.success(res.message);
          setOtpSend(true)
        } else {
          toast.error(res.message)
        }
      }).catch(error => {
        setLoading(false)
        toast.error(error?.message)
      })
    } else {
      toast.error("Please Enter the Email");
    }
  }
const verifyOtpHandle =()=>{
  if(otp){
    setLoading(true)
        verifyOtp({email:email,otp:otp}).then((res)=>{
          setLoading(false)
          if (res.status == true) {
            toast.success(res.message);
            setNewPassword(true)
          } else {
            toast.error(res.message)
          }
        }).catch(error => {
          setLoading(false)
          toast.error(error?.message)
        })
  }else{
    toast.error("Please Enter The OTP");
  }
}
const resetPasswordHandle=()=>{
  // Regular expression to validate password
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

  if (!passwordRegex.test(password)) {
    toast.error("Password must have a capital letter, special character, and be at least 8 characters long.");
      return;
  }

  if (password !== confirmPassword) {
      toast.error("Password and confirm password do not match.");
      return;
  }
setLoading(true)
  resetPassword({email:email,password:password}).then((res)=>{
    setLoading(false)
    if (res.status == true) {
      toast.success(res.message);
      setTimeout(() => {
      navigate("/");
      }, 1000);
    } else {
      toast.error(res.message)
    }
  }).catch(error => {
    setLoading(false)
    toast.error(error?.message)
  })

}
  return (
    <div className=" min-vh-100 container-fluid">
      <ToastContainer />
      <div className="row justify-content-between align-items-center">
        <div
          className="col-lg-6 col-xl-5 d-flex justify-content-center position-relative mt-5 mt-lg-0"
          style={{ height: "100%" }}
        >
          <div className="col-9 ">
            {newPassword ? (
              <>
                <div className="rounded-3 px-md-5 position-relative">
                  <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-black">
                    New Password
                  </h3>
                  <input
                    className="px-3 mt-4 custom_input border-1 py-2 w-100 rounded-2"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter New Password"
                    name="new Password"
                  />
                  <input
                    className="px-3 mt-4 custom_input border-1 py-2 w-100 rounded-2 mt-3"
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Retype New Password"
                    name="Retype New Password"
                  />
                  {/* <Link to="/admin/course-tab"> */}
                  <button onClick={resetPasswordHandle} className="bg-primary mt-4 submit_btn">
                  {loading ? <Spinner /> : "Done"}
                  </button>
                  {/* </Link> */}
                </div>
              </>
            ) : (
              <>
                {otpSend ? (
                  <>
                    {" "}
                    <div className="rounded-3 px-md-5 position-relative">
                      <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-black">
                        OTP
                      </h3>
                      <input
                        className="px-3 mt-4 custom_input border-1 py-2 w-100 rounded-2"
                        type="number"
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        name="otp"
                      />
                      <div className=" mt-4">
                        <button
                        disabled={loading}
                          onClick={verifyOtpHandle}
                          className="bg-primary mt-2 submit_btn"
                        >
                             {loading ? <Spinner /> : "Done"}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="rounded-3 px-md-5 position-relative">
                      <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-black">
                        Email
                      </h3>
                      <input
                        className="px-3 mt-4 custom_input border-1 py-2 w-100 rounded-2"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Email"
                        name="email"
                      />
                      <div className=" mt-4">
                        <button
                          disabled={loading}
                          onClick={sendOtpHandle}
                          className=" bg-primary mt-2 submit_btn"
                        >
                          {loading ? <Spinner /> : "Done"}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="col-lg-6 d-lg-block d-none mt-5 mt-lg-0 px-0"
          style={{ height: "100vh" }}
        >
          <div className=" custom_bg w-100 h-100">
            <img className="w-100 h-100 " src={login_img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
