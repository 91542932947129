import React from "react";
// import crossblack_icon from "../../assets/images/png/blackcrose_icon.png";
import Cookies from 'js-cookie';
import { getUser } from "../../utils/appConfig";
const BlogTab = () => {
  const userData = getUser();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center py-sm-3 my_3 ms-3 py-2">
        <h2 className=" fs_37 fw-semibold ff_heebo py-1 mb-0">Settings</h2>
      </div>
      <div className="border mb-4 "></div>
      <div className="py-md-3 px-sm-4 px-3">
        <div className="row">
          <div className="col-12 text-center">
            <img className="rounded-circle user_image_custom" src={userData?.image} alt={userData?.name + " image"} />
            <br />
            <h2 className="fw-semibold mt-3 fs-5">{userData?.name}</h2>
          </div>
        </div>
        <div className="row mt-5 bg-white px-2 rounded-2">
          <div className="px-2 my-3 col-sm-4">
            <div className="py-3 px-2 border border-1 rounded-2">
              <p className="fw-bold mb-1">Name</p>
              <p className="fw-normal">{userData?.name}</p>
            </div>
          </div>
          <div className="px-2 my-3 col-sm-4">
            <div className="py-3 px-2 border border-1 rounded-2">
              <p className="fw-bold mb-1">Mobile Number</p>
              <p className="fw-normal">{userData?.mobile}</p>
            </div>
          </div>
          <div className="px-2 my-3 col-sm-4">
            <div className="py-3 px-2 border border-1 rounded-2">
              <p className="fw-bold mb-1">Email Id</p>
              <p className="fw-normal">{userData?.email}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogTab;
