import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  loginSuccess,
  loginFailure,
  forgetPasswordSuccess,
  forgetPasswordFailure,
  verifyOtpSuccess,
  verifyOtpFailure,
  newPasswordSuccess,
  newPasswordFailure,
} from '../slice/authSlice';
import { setError, setSuccess } from '../slice/responseSlice';
const baseUrl = process.env.REACT_APP_API_URL;
function* userLogin(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/supplier-console-login`, action.payload));
    if (response.data.status == true) {
      yield put(setSuccess(response.data.message))
      yield put(loginSuccess(response.data.data));
    } else {
      yield put(setError(response.data.message))
      yield put(loginFailure());

    }
  } catch (error) {
    yield put(loginFailure(error.message));
    yield put(setError(error.message))

  }
}

function* forgetPassword(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/forget-password`, action.payload));
    yield put(forgetPasswordSuccess(response.data));
  } catch (error) {
    yield put(forgetPasswordFailure(error.message));
  }
}

function* verifyOtp(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/verify-otp`, action.payload));
    yield put(verifyOtpSuccess(response.data));
  } catch (error) {
    yield put(verifyOtpFailure(error.message));
  }
}

function* newPassword(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/create-new-password`, action.payload));
    yield put(newPasswordSuccess(response.data));
  } catch (error) {
    yield put(newPasswordFailure(error.message));
  }
}

export default function* authSaga() {
  yield takeLatest('auth/login', userLogin);
  yield takeLatest('auth/forgetPassword', forgetPassword);
  yield takeLatest('auth/verifyOtp', verifyOtp);
  yield takeLatest('auth/newPassword', newPassword);
}
