import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import crossblack_icon from "../../assets/images/png/blackcrose_icon.png";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editProducts, getSingleProduct } from "../../redux/slice/productSlice";
import { getCategories } from "../../redux/slice/categorySlice";
import { getUnits } from "../../redux/slice/unitSlice";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap/esm";

const EditModal = ({
  toggleeditProductModal,
  editProduct,
  handleEditCheckboxChange,
  editNeedQuotation,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);
  const { loading, success, singleProduct } = useSelector(
    (state) => state.product
  );
  const { units } = useSelector((state) => state.unit);
  const [needQuotation, setNeedQuotation] = useState(false);

  const [productDetails, setProductDetails] = useState({
    name: "",
    image: null,
    category: "",
    localizedName: "",
    price: "",
    discount: "",
    unit: "",
    description: "",
    type: 2,
    discountType: 1,
    localizedName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes

    // Check if the file size exceeds the maximum allowed size
    if (imageFile && imageFile.size > maxSize) {
      // Handle the case where the file size exceeds the limit
      toast.error("Image size exceeds the maximum allowed size of 1MB.");
      // Optionally, you can clear the input field or take other actions
      return;
    }
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      image: imageFile,
    }));
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video", "button", "table"],
      ["clean"],
      ["code-block"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const handleQuotationCheckboxChange = (e) => {
    setNeedQuotation(e.target.checked);
    if (e.target.value == true) {
      setProductDetails({
        ...productDetails,
        price: "",
        discount: "",
      });
    }
  };
  // useEffect(() => {
  //   if (id) {
  //     dispatch(getSingleProduct(id))
  //   }
  // }, [id])
  useEffect(() => {
    if (singleProduct) {
      setProductDetails({
        name: singleProduct.product_name,
        discount: singleProduct.discounted_price,
        unit: singleProduct.unit_id,
        description: singleProduct.product_desc,
        localizedName: singleProduct.localized_name,
        price: singleProduct.product_price,
        category: singleProduct.category_id,
      });
      if (singleProduct.is_quotation == 1) {
        setNeedQuotation(false);
      } else {
        setNeedQuotation(true);
      }
    }
  }, [singleProduct]);
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getUnits());
  }, []);

  const editProductHandle = () => {
    let requiredFields = [];
    if (needQuotation == false) {
      requiredFields = ["name", "category", "unit", "description", "price"];
    } else {
      requiredFields = ["name", "category", "unit", "description"];
    }
    // Check if any required field is empty
    const missingField = requiredFields.find((field) => !productDetails[field]);

    if (missingField) {
      // Display error toast
      toast.error(`Please fill in the ${missingField} field.`);
      return; // Stop further execution
    }
    if (productDetails.price < 1 || productDetails.discount < 0) {
      toast.error("Price and discount price can't be negative");
      return;
    }
    const formData = new FormData();
    formData.append("product_id", singleProduct?.product_id);
    if (productDetails.image) {
      formData.append("image", productDetails.image);
    }
    formData.append("category_id", productDetails.category);
    formData.append("unit", productDetails.unit);
    formData.append("name", productDetails.name);
    formData.append("description", productDetails.description);
    formData.append("type", productDetails.type);
    formData.append("price", productDetails.price);
    formData.append("discount", productDetails.discount);
    formData.append("localized_name", productDetails.localizedName);
    // formData.append("discount_type", productDetails.discountType)
    formData.append("toggle", "no");

    dispatch(editProducts({ data: formData }));
  };
  useEffect(() => {
    if (success == "edit") {
      toggleeditProductModal();
      dispatch(getSingleProduct({ id: id }));
    }
  }, [success]);
  return (
    <div>
      <Modal show={editProduct} centered className="">
        <div className="rounded-3 px-4 card position-relative">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleeditProductModal}
              width={20}
              className="me-2 mt-2 cursor-pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>

          <Modal.Body>
            <h4>Edit Product</h4>
            <div className="row align-items-center">
              <div className="d-flex col-12 col-sm-6 flex-column col-3 mt-4">
                <label>Name</label>
                <input
                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="text"
                  name="name"
                  value={productDetails.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex col-12 col-sm-6 flex-column col-3 mt-4">
                <label>Image</label>
                <input
                  className="custom_admin_input border border-1 border-black mt-2  px-3 py-2 w-100"
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                />
              </div>

              <div className="d-flex col-12 col-sm-6 flex-column col-3 mt-4">
                <label for="category">Category</label>
                <select
                  className="custom_admin_input col-6 mt-1 w-100 py-2"
                  id="category"
                  name="category"
                  value={productDetails.category}
                  onChange={handleInputChange}
                >
                  <option value="">Select Category</option>
                  {categories?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="d-flex col-12 col-sm-6 flex-column mt-4">
                <label for="unit">Unit</label>
                <select
                  className=" w-100 mt-1 py-2"
                  id="unit"
                  name="unit"
                  value={productDetails.unit}
                  onChange={handleInputChange}
                >
                  <option value="">Select Unit</option>
                  {units?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="d-flex col-12 col-sm-6 flex-column col-3 mt-4">
                <lable>Localized Name</lable>
                <input
                  value={productDetails.localizedName}
                  name="localizedName"
                  onChange={handleInputChange}
                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="text"
                />
              </div>
            </div>
            <div className="d-flex w-100 flex-column  mt-4">
              <label>Description</label>
              <ReactQuill
                value={productDetails.description}
                onChange={(value) =>
                  setProductDetails({ ...productDetails, description: value })
                }
                theme="snow"
                placeholder="Add Description"
                modules={modules}
              />
            </div>

            {!needQuotation && (
              <div className="row">
                <div className="d-flex col-12 col-sm-6 flex-column col-3 mt-4">
                  <label>Price</label>
                  <input
                    className="custom_admin_input mt-2 px-3 py-2 w-100"
                    type="number"
                    name="price"
                    value={productDetails.price}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex col-12 col-sm-6 flex-column col-3 mt-4">
                  <label>Discount</label>
                  <input
                    className="custom_admin_input mt-2 px-3 py-2 w-100"
                    type="number"
                    name="discount"
                    value={productDetails.discount}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            )}

            <span>
              <button
                disabled={loading}
                onClick={editProductHandle}
                className="bg-primary mt-4 submit_btn"
                type="submit"
              >
                {loading ? <Spinner /> : "Submit"}
              </button>
            </span>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default EditModal;
