import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import crossblack_icon from "../../assets/images/png/blackcrose_icon.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct } from "../../redux/slice/productSlice";

const DeleteModal = ({ handleShowModal, showModal }) => {
  const modalStyles = {
    maxWidth: "400px",
    margin: "auto",
  };
  const { singleProduct } = useSelector((state) => state.product);
  const { success } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  // const { id } = useParams();
  const navigate = useNavigate();
  const deleteProductHandle = () => {
    dispatch(deleteProduct({ id: singleProduct?.product_id }));
  };
  useEffect(() => {
    if (success == "delete") {
      navigate("/admin/course-tab");
    }
  }, [success]);
  return (
    <div>
      {" "}
      <div className="delete position-relative">
        <Modal
          className="modal-narrow position-absolute top-50 start-50 translate-middle position-relative"
          centered
          show={showModal}
        >
          <div className="position-absolute top-0 end-0">
            <img
              onClick={handleShowModal}
              width={20}
              className="me-2 mt-2 cursor-pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>
          <div style={modalStyles}>
            <Modal.Header className="border-bottom-0"></Modal.Header>
            <Modal.Body className="pt-0">
              <h4 className="text-center">
                Are you sure you want to<br className="d-lg-block d-none"></br>{" "}
                delete this item?
              </h4>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center  border-top-0 pt-0">
              {/* <Button
                variant="secondary"
                className="rounded-1 px-4"
                onClick={handleCloseModal}
              >
                Cancel
              </Button> */}

              <Button
                onClick={deleteProductHandle}
                variant="danger"
                className="rounded-1 px-4"
              >
                Delete
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteModal;
