import React, { useEffect, useState } from "react";
import icon from "../../assets/images/png/icon.jpg";
import DeleteModal from "./DeleteModal";
import AddStock from "./AddStock";
import EditModal from "./EditModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProduct } from "../../redux/slice/productSlice";
import LoadingSpinner from "./LoadingSpinner";
import { ToastContainer } from "react-toastify";

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { singleProduct, loading } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [viewDetails, setViewDetails] = useState(false);
  const [editProduct, setEditProduct] = useState(false);

  const toggleeditProductModal = () => {
    setEditProduct(!editProduct);
  };
  const [addStok, setAddStok] = useState(false);

  const toggleAddStokModal = () => {
    setAddStok(!addStok);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video", "button", "table"],
      ["clean"],
      ["code-block"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const [editNeedQuotation, setEditNeedQuotation] = useState(false);
  const handleEditCheckboxChange = (e) => {
    setEditNeedQuotation(e.target.checked);
  };

  const modalStyles = {
    maxWidth: "400px", // Set the desired width
    margin: "auto", // Center the modal horizontally
  };

  useEffect(() => {
    if (id) {
      dispatch(getSingleProduct({ id: id }));
    }
  }, [id]);

  return (
    <div className="position-relative ">
      {loading ? <LoadingSpinner /> : ""}
      <>
        <ToastContainer />
        <div>
          <div className="d-flex justify-content-between align-items-center p-md-3 px-sm-5 px-4 pt-4">
            <div className="d-flex algin-items-center mt-6">
              <div
                onClick={() => navigate(-1)}
                className="cursor-pointer d-flex algin-items-center"
              >
                <span className="bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center p-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6185 2.99004C16.5024 2.87363 16.3645 2.78127 16.2126 2.71826C16.0608 2.65524 15.898 2.6228 15.7335 2.6228C15.5691 2.6228 15.4063 2.65524 15.2545 2.71826C15.1026 2.78127 14.9647 2.87363 14.8485 2.99004L6.53854 11.3C6.44583 11.3926 6.37229 11.5024 6.32211 11.6234C6.27192 11.7444 6.24609 11.8741 6.24609 12.005C6.24609 12.136 6.27192 12.2657 6.32211 12.3867C6.37229 12.5076 6.44583 12.6175 6.53854 12.71L14.8485 21.02C15.3385 21.51 16.1285 21.51 16.6185 21.02C17.1085 20.53 17.1085 19.74 16.6185 19.25L9.37854 12L16.6285 4.75004C17.1085 4.27004 17.1085 3.47004 16.6185 2.99004Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <h2 className="ms-3 mb-0 mt-1 fs_30 fw-semibold">
                  {singleProduct?.product_name}
                </h2>
              </div>
            </div>
          </div>
          <div className="border mb-4 mt-2"></div>
        </div>

        <div className="d-flex align-items-center justify-content-end justify-content-start flex-wrap mb-4 gap-sm-4 gap-2 mt-2  p-md-3 px-sm-5 px-4">
          <div>
            <button
              onClick={handleShowModal}
              className="px-4 py-2 rounded-1 bg-danger text-white border-0"
            >
              Delete
            </button>
            <button
              className="px-4 ms-2 py-2 rounded-1 bg-secondary text-white border-0"
              onClick={toggleAddStokModal}
            >
              Add Stock
            </button>
            <button
              onClick={toggleeditProductModal}
              className="px-4 ms-2 py-2 rounded-1 bg-primary text-white border-0"
            >
              Edit Product
            </button>
          </div>
        </div>
        <div className="px-sm-5 px-3">
          <div className="row align-items-center justify-content-center  p-md-3 px-sm-5 px-sm-4">
            <div className="mt-5 col-md-9 bg-white rounded-2 p-sm-5 pt-5 mb-5">
              <div className="row align-items-start justify-content-between">
                <div className="col-sm-5">
                  <img
                    className="rounded-2 w-100 h-100 obj_fit"
                    src={singleProduct?.product_image}
                    alt="Image"
                  />
                </div>
                <div className="col-sm-6 h-100 border border-1">
                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Name:</strong>
                    <p className="mb-0">{singleProduct?.product_name}</p>
                  </div>

                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Category:</strong>
                    <p className="mb-0">{singleProduct?.category_name}</p>
                  </div>

                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Status:</strong>
                    <p
                      className={
                        singleProduct?.product_status == 1
                          ? "approved_tab rounded-2 py-1 px-2"
                          : "review_tab rounded-2 py-1 px-2"
                      }
                    >
                      {singleProduct?.product_status == 1
                        ? "Approved"
                        : "Under Review"}
                    </p>
                  </div>

                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Description:</strong>
                    <p className="mb-0">{singleProduct?.product_desc}</p>
                  </div>
                  {singleProduct?.is_quotation != 2 ? (
                    <>
                      <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                        <strong>Price:</strong>
                        <p className="mb-0">{singleProduct?.product_price}</p>
                      </div>
                      <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                        <strong>Discount Amount:</strong>
                        <p className="mb-0">
                          {singleProduct?.discounted_price}
                        </p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Quantity:</strong>
                    <p className="mb-0">{singleProduct?.quantity}</p>
                  </div>
                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Quotation:</strong>
                    <p className="mb-0">
                      {singleProduct?.is_quotation == 2 ? "Yes" : "No"}
                    </p>
                  </div>
                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Unit:</strong>
                    <p className="mb-0">{singleProduct?.unit_name}</p>
                  </div>
                  <div className="d-flex mt-2 w-100 rounded-2 bg-white  p-1 align-items-center justify-content-between">
                    <strong>Localized Name:</strong>
                    <p className="mb-0">{singleProduct?.localized_name}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <EditModal
        editProduct={editProduct}
        toggleeditProductModal={toggleeditProductModal}
        editNeedQuotation={editNeedQuotation}
        handleEditCheckboxChange={handleEditCheckboxChange}
      />{" "}
      <DeleteModal showModal={showModal} handleShowModal={handleShowModal} />{" "}
      <AddStock
        type={singleProduct?.is_quotation}
        unit={singleProduct?.unit_name}
        addStok={addStok}
        toggleAddStokModal={toggleAddStokModal}
      />{" "}
    </div>
  );
};

export default ProductDetails;
