// categoriesSaga.js

import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios'; 
import {  getCategoriesSuccess, getCategoriesFailure } from '../slice/categorySlice';
const baseUrl = process.env.REACT_APP_API_URL;

function* getCategories() {
  try {

    const response = yield call(() => axios.post(`${baseUrl}/get-category`,{type:"en"}));
    yield put(getCategoriesSuccess(response.data.data));
  } catch (error) {
    yield put(getCategoriesFailure(error.message));
  }
}

// Watcher saga
export default function* categoriesSaga() {
  yield takeLatest('category/getCategories', getCategories);
}
