import axios from "axios";
import { getToken } from "../utils/appConfig";
const baseUrl = process.env.REACT_APP_API_URL;

export const getImageUrl = async (data) => {
  const url = baseUrl + `/get-image-url`;
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
