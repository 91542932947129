import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import crossblack_icon from "../../assets/images/png/blackcrose_icon.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { uploadProduct } from "../../redux/slice/productSlice";
import ExcelSheet from "../../assets/sample/instruction-sample.zip";
import { getImageUrl } from "../../services/ImageGeneratorServices";
const ImageUrlGenerator = ({ toggleImageGenerator, ImageGenerator }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const modalStyles = {
    maxWidth: "400px", // Set the desired width
    margin: "auto", // Center the modal horizontally
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size <= 1024 * 1024) {
      // Check if the file extension is in the allowed list
      setSelectedFile(file);
    } else {
      toast.error(
        "Please select an image file with size less than or equal to 1 MB"
      );
    }
  };

  const submitHandle = () => {
    setLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      getImageUrl(formData)
        .then((res) => {
          setLoading(false);
          if (res.status == true) {
            setUrl(res.data);
            toast.success(res.message);
            setSelectedFile(null);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      setLoading(false);
      toast.error("Please select a Image");
    }
  };
  const copyText = () => {
    const textToCopy = url;
    navigator.clipboard.writeText(textToCopy).then(() => {
      toast.success("Copied");
    });
  };
  return (
    <div>
      <Modal
        className="imprt_modal position-absolute top-50 start-50 translate-middle  position-relative"
        centered
        show={ImageGenerator}
      >
        <div style={modalStyles} className="position-absolute top-0 end-0">
          <img
            onClick={() => {
              setUrl("");
              setSelectedFile(null);
              toggleImageGenerator();
            }}
            width={20}
            className="me-2 mt-2 cursor-pointer"
            src={crossblack_icon}
            alt="Close"
          />
        </div>
        <div>
          <Modal.Body className="px-2 pb-0 mt-4">
            <label
              className="mb-2 fw-semibold"
              style={{ fontSize: "14px" }}
              htmlFor=""
            >
              Upload Image{" "}
              <span style={{ fontSize: "10px" }}>(max size 1 MB)</span>
            </label>
            <input
              type="file"
              className="custom_admin_input w-100 px-3 mt-1 py-2"
              onChange={handleFileChange}
            />
            {/* <h5 className="my-4">Instructions</h5>
            <div className=" pb-3">
              <p className="ff_heebo fw-normal fs_15 mb-1">
                <strong>1.</strong> Lorem ipsum dolor sit amet.
              </p>
              <p className="ff_heebo fw-normal fs_15 mb-1">
                <strong>2.</strong> Lorem ipsum dolor sit amet.
              </p>
              <p className="ff_heebo fw-normal fs_15 mb-1">
                <strong>3.</strong> Lorem ipsum dolor sit amet.
              </p>
            </div> */}
            {/* <label
              className="fw-semibold pt-3 mb-2"
              style={{ fontSize: "14px" }}
              htmlFor=""
            >
              Image Url
            </label> */}
            {url ? (
              <div class="d-flex align-items-center mt-2">
                <input
                  className="p-2 border border-0 outline-none border-black w-100"
                  value={url}
                  type="text"
                />
                <span onClick={copyText} className="cursor-pointer">
                  <svg
                    class="copy-icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 11C6 8.172 6 6.757 6.879 5.879C7.757 5 9.172 5 12 5H15C17.828 5 19.243 5 20.121 5.879C21 6.757 21 8.172 21 11V16C21 18.828 21 20.243 20.121 21.121C19.243 22 17.828 22 15 22H12C9.172 22 7.757 22 6.879 21.121C6 20.243 6 18.828 6 16V11Z"
                      stroke="black"
                      stroke-width="1.5"
                    />
                    <path
                      d="M6 19C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V10C3 6.229 3 4.343 4.172 3.172C5.343 2 7.229 2 11 2H15C15.7956 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5"
                      stroke="black"
                      stroke-width="1.5"
                    />
                  </svg>
                </span>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="">
            <Button
              disabled={false}
              variant="secondary"
              className="submit_btn"
              onClick={submitHandle}
            >
              {loading ? <Spinner /> : "Submit"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default ImageUrlGenerator;
