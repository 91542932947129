import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import crossblack_icon from "../../assets/images/png/blackcrose_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../redux/slice/categorySlice";
import { changeCurrentPage, getProducts, setFilterData, supplierTypeData } from "../../redux/slice/productSlice";

const FilterModal = ({ toggleFilterModal, filter }) => {
  const InitalproductType = useSelector(state => state.product.productType)
  const [productType, setProductType] = useState(InitalproductType);
  const [needQuotation, setNeedQuotation] = useState(false);
  const [productDetails, setProductDetails] = useState({
    name: "",
    category: "",
    localizedName: "",
    type: 1,
  });
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };
  const { categories } = useSelector((state) => state.category);
  useEffect(() => {
    dispatch(getCategories());
  }, [])
  const handleQuotationCheckboxChange = (e) => {
    setNeedQuotation(e.target.checked);
    if (e.target.checked == true) {
      setProductDetails({
        ...productDetails,
        type: 2
      })
    } else {
      setProductDetails({
        ...productDetails,
        type: 1
      })
    }
  };
  const handleFilter = () => {
    dispatch(supplierTypeData(productType))
    dispatch(changeCurrentPage(1))
    dispatch(getProducts({ productType: productType, page: 1 }));
    dispatch(setFilterData(productDetails))
    toggleFilterModal();
  }
  const clearFilterHandle =()=>{
    dispatch(setFilterData({
      name: "",
      category: "",
      localizedName: "",
      type: "",
    }))
    setProductDetails({
      name: "",
      category: "",
      localizedName: "",
      type: "",
    })
    toggleFilterModal()
  }
  return (
    <div>
      {" "}
      <Modal show={filter} centered className="">
        <div className="rounded-3 px-4 card position-relative">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFilterModal}
              width={20}
              className="me-2 mt-2 cursor-pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>

          <Modal.Body>
            <h4>Filter Product</h4>
            <div className="row align-items-center">
              <div className="d-flex col-6 flex-column col-3 mt-4">
                <lable>Name</lable>
                <input
                  value={productDetails.name}
                  onChange={handleInputChange}
                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="text"
                  name="name"
                />
              </div>
              <div className="d-flex col-6 col-3 flex-column col-3 mt-4">
                <lable>Select Products</lable>
                <select value={productType} onChange={(e) => setProductType(e.target.value)} className="col-12 p-2" name="" id="">
                  <option value="all">Any</option>
                  <option value="approved">Approved</option>
                  <option value="under_review">Under Review</option>
                </select>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="d-flex col-6 flex-column mt-4">
                <lable >Category</lable>
                <select
                  className="custom_admin_input col-6 mt-2 w-100 py-2"
                  id="category"
                  name="category"
                  value={productDetails.category}
                  onChange={handleInputChange}
                >
                  <option value="">Select Any</option>
                  {categories?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>{item.name}</option>
                    )
                  })}
                </select>
              </div>
              <div className="d-flex col-6 col-3 flex-column col-3 mt-4">
                <lable>Localized name</lable>
                <input

                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="text"
                  value={productDetails.localizedName}
                  onChange={handleInputChange}
                  name="localizedName"
                />
              </div>
            </div>

            <div className="d-flex w-100 gap-2 align-items-center mt-4">
              <input
                type="checkbox"
                id="quotation"
                checked={needQuotation}
                onChange={handleQuotationCheckboxChange}
              />
              <label htmlFor="click">Need Quotation for Pricing</label>
            </div>

            <div className="d-flex align-items-center mt-4 justify-content-between">
              <button
                onClick={handleFilter}
                className="bg-primary  submit_btn"
                type="submit"
              >
                Submit
              </button>

              <Button
                variant="secondary"
                className="ms-5 submit_btn mt-0"
                onClick={clearFilterHandle}
              >
                Clear Filter
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default FilterModal;
