import Cookies from "js-cookie"
const data = Cookies.get("auth")

export const getToken=()=>{
    const auth = JSON.parse(data)
    return auth?.token
}
export const getUser=()=>{
    const auth = JSON.parse(data)
    return auth
}