import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getProductsSuccess,
  getProductsFailure,
  getSingleProductSuccess,
  getSingleProductFailure,
  createProductSuccess,
  createProductFailure,
  editProductSuccess,
  editProductFailure,
  deleteProductSuccess,
  deleteProductFailure,
  addStockFailure,
  addStockSuccess,
  uploadProductSuccess,
  uploadProductFailure
} from '../slice/productSlice';
import axios from 'axios';
import { setError, setSuccess } from '../slice/responseSlice';
import { getToken } from '../../utils/appConfig';
const baseUrl = process.env.REACT_APP_API_URL;

function* handleGetProducts(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/get-product`, { type: action.payload.productType, page_no: action.payload.page || 1 }, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }));
    yield put(getProductsSuccess(response.data.data));
  } catch (error) {
    yield put(getProductsFailure(error.message));
  }
}

function* handleGetSingleProduct(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/getProductDetail`, { product_id: action.payload.id }
      , {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }));
    if (response.data.status == true) {
      yield put(getSingleProductSuccess(response.data.data));
    } else {
      yield put(getSingleProductFailure(response.data.message));
    }
  } catch (error) {
    yield put(getSingleProductFailure(error.message));
  }
}

function* handleCreateProduct(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/add-product`, action.payload.data, {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    }));
    if (response.data.status == true) {
      yield put(setSuccess(response.data.message))
      yield put(createProductSuccess());
    } else {
      yield put(setError(response.data.message))
      yield put(createProductFailure(response.data.message));
    }
    // Dispatch success action with the created product
  } catch (error) {
    yield put(setError(error.message))
    yield put(createProductFailure(error.message)); // Dispatch failure action with error message
  }
}

function* handleEditProduct(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/edit-products`,  action.payload.data 
      , {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }));
    if (response.data.status == true) {
      yield put(setSuccess(response.data.message))
      yield put(editProductSuccess()); // Dispatch success action with the updated product
    } else {
      yield put(editProductFailure(response.data.message)); // Dispatch failure action with error message
      yield put(setError(response.data.message))
    }

  } catch (error) {
    yield put(editProductFailure(error.message)); // Dispatch failure action with error message
  }
}

function* handleDeleteProduct(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/delete-product`, { product_id: action.payload.id }
      , {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }));
    if (response.data.status == true) {
      yield put(deleteProductSuccess());
      yield put(setSuccess("Product Deleted Successfully"));
    } else {
      yield put(setError(response.data.message));
      yield put(deleteProductFailure(response.data.message));
    }
  } catch (error) {
    yield put(setError(error.message));
    yield put(deleteProductFailure(error.message)); // Dispatch failure action with error message
  }
}
function* handleAddStock(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/add-stock`, action.payload.data
      , {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }));
    if (response.data.status == true) {
      yield put(addStockSuccess(action.payload.data.stock));
      yield put(setSuccess(response.data.message));
    } else {
      yield put(setError(response.data.message));
      yield put(addStockFailure(response.data.message));
    }
  } catch (error) {
    yield put(setError(error.message));
    yield put(addStockFailure(error.message)); // Dispatch failure action with error message
  }
}
function* uploadProductHandle(action) {
  try {
    const response = yield call(() => axios.post(`${baseUrl}/upload-product`, action.payload
      , {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }));
    if (response.data.status == true) {
      yield put(uploadProductSuccess());
      yield put(setSuccess(response.data.message));
    } else {
      yield put(setError(response.data.message));
      yield put(uploadProductFailure(response.data.message));
    }
  } catch (error) {
    yield put(setError(error.message));
    yield put(uploadProductFailure(error.message)); // Dispatch failure action with error message
  }
}

function* productSaga() {
  yield takeLatest('product/getProducts', handleGetProducts);
  yield takeLatest('product/getSingleProduct', handleGetSingleProduct);
  yield takeLatest('product/createProduct', handleCreateProduct);
  yield takeLatest('product/editProducts', handleEditProduct);
  yield takeLatest('product/deleteProduct', handleDeleteProduct);
  yield takeLatest('product/addStock', handleAddStock);
  yield takeLatest('product/uploadProduct', uploadProductHandle);
}

export default productSaga;
