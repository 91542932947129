import { all } from 'redux-saga/effects';
import authSaga from '../saga/authSaga'; 
import productSaga from './productSaga';
import categoriesSaga from './categorySaga';
import unitSaga from './unitSaga';

export default function* rootSaga() {
  yield all([
    authSaga(), 
    productSaga(),
    categoriesSaga(),
    unitSaga()
  ]);
}
