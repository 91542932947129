import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  success: null,
  error: null,
};

const responseSlice = createSlice({
  name: 'response',
  initialState,
  reducers: {
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearSuccess(state) {
      state.success = null;
    },
  },
});

export const { setSuccess, setError, clearError,clearSuccess } = responseSlice.actions;

export default responseSlice.reducer;
