import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  units: [],
  loading: false,
  error: null,
};

const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    getUnits(state) {
      state.loading = true;
      state.error = null;
    },
    getUnitsSuccess(state, action) {
      state.loading = false;
      state.units = action.payload;
      state.error = null;
    },
    getUnitsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getUnits, getUnitsSuccess, getUnitsFailure } = unitSlice.actions;

export default unitSlice.reducer;
