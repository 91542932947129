import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [], 
  singleProduct: null, 
  loading: false, 
  error: null, 
  success:null,
  filterData:{
    name: "",
    category: "",
    localizedName: "",
    type: "",
  },
  page:1,
  productType:"all"
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getProducts(state) {
      state.loading = true;
      state.success=null;
      state.error = null;
    },
    getProductsSuccess(state, action) {
      state.loading = false;
      state.products = action.payload;
    },
    getProductsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getSingleProduct(state) {
      state.loading = true;
      state.error = null;
    },
    getSingleProductSuccess(state, action) {
      state.loading = false;
      state.success=null
      state.singleProduct = action.payload;
    },
    getSingleProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createProduct(state) {
      state.loading = true;
      state.error = null;
      state.success=null
    },
    createProductSuccess(state, action) {
      state.loading = false;
      // state.products.push(action.payload);
      state.success="create"
    },
    createProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    editProducts(state) {
      state.loading = true;
      state.error = null;
    },
    editProductSuccess(state, action) {
      state.loading = false;
      // const updatedProduct = action.payload;
      // const index = state.products.findIndex(product => product.id === updatedProduct.id);
      // if (index !== -1) {
      //   state.products[index] = updatedProduct;
      // }
      state.success="edit"
    },
    editProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteProduct(state) {
      state.loading = true;
      state.error = null;
      state.success=null
    },
    deleteProductSuccess(state, action) {
      state.loading = false;
      const productId = action.payload;
      state.success="delete"
      state.products = state.products.filter(product => product.id !== productId);
    },
    deleteProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addStock(state) {
      state.loading = true;
      state.error = null;
    },
    addStockSuccess(state, action) {
      state.loading = false;
      state.success="stock"
      state.singleProduct = {...state.singleProduct,quantity:state.singleProduct.quantity+Number(action.payload)}
    },
    addStockFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    uploadProduct(state) {
      state.loading = true;
      state.error = null;
      state.success=null
    },
    uploadProductSuccess(state, action) {
      state.loading = false;
      state.success="upload"
    },
    uploadProductFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setFilterData(state, action) {
      state.filterData = action.payload;
    },
    supplierTypeData(state, action) {
      state.productType = action.payload;
    },
    changeCurrentPage(state, action) {
      state.page = action.payload;
    },
  },
});

export const {
  changeCurrentPage,
  supplierTypeData,
  setFilterData,
  getProducts,
  getProductsSuccess,
  getProductsFailure,
  getSingleProduct,
  getSingleProductSuccess,
  getSingleProductFailure,
  createProduct,
  createProductSuccess,
  createProductFailure,
  editProducts,
  editProductSuccess,
  editProductFailure,
  deleteProduct,
  deleteProductSuccess,
  deleteProductFailure,
  addStock,
  addStockSuccess,
  addStockFailure,
  uploadProduct,
  uploadProductSuccess,
  uploadProductFailure,
} = productSlice.actions;

export default productSlice.reducer;
